<template>

  <div 
  class="container-fluid main-color"
  >
  
    <div class="row">

      <div class="col-12">

        <div class="page-title-box">
          <h4 class="page-title">Edit Orientation Criteria</h4>
        </div>

      </div>

    </div>

    <div 
    class="row"
    >
    
  
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">
          
            <form>
             
                <div class="row mb-3">

                    <div class="col-lg-3">

                      <label for="name"> 
                          Criteria Name 
                          <span class="text-danger">*</span>
                      </label>

                    </div>

                    <div class="col-lg-6">
                      
                          <input 
                          name="name"
                          id="name"
                          type="text"  
                          class="form-control" 
                          :class="{
                            'p-invalid':this.v$.formData.name.$error,
                          }"
                          v-model="formData.name"/>

                          <v-errors
                            :vuelidateErrors="{
                              errors: this.v$.formData.name.$errors,
                              value: 'Name',
                            }"
                          ></v-errors>

                    </div>

                </div>

                <div class="row mb-3">
                  
                  <div class="col-lg-3">
                    <label for="program"> 
                        Program Name 
                        <span class="text-danger">*</span>
                    </label>
                  </div>

                  <div class="col-lg-6">

                      <div class="d-flex">
                          <div class="d-flex align-items-center me-3">
                            <input
                              type="radio"
                              name="choose"
                              :checked="formData.all_check"
                              @click=" 
                              formData.all_check = 1;
                              formData.level = '';
                              formData.assessment_criteria_id = '';"
                            />
                            <label>All</label>
                          </div>
                          <div class="d-flex align-items-center">
                            <input
                              type="radio"
                              name="choose"
                              :checked="!formData.all_check"
                              @click="
                              formData.all_check = 0;
                                formData.level = '';
                                formData.assessment_criteria_id = ''
                                formData.program_ids = [];
                              "
                            />
                            <label>Select</label>
                          </div>
                        </div>

                        <v-select  v-show="!formData.all_check"
                        @option:selected=" 
                        formData.level = '';
                        formData.assessment_criteria_id = '';
                        "
                        name="program"
                        id="program"
                        :options="moduleList" 
                        label="name"
                        :loading="!moduleList.length"
                        :class="{
                          'p-invalid': this.v$.formData.program_ids.$error,
                        }"
                        v-model="formData.program_ids"
                        :selectable="(data) => selectedProgram(data)"
                        multiple
                        />

                        <v-errors v-show="!formData.all_check"
                          :vuelidateErrors="{
                            errors: this.v$.formData.program_ids.$errors,
                            value: 'Program',
                          }"
                        ></v-errors>

                  </div>

                </div>

                <div class="row mb-3">
                    
                    <div class="col-lg-3">
                      <label for="remark"> 
                          Level 
                          <span class="text-danger">*</span>
                      </label>
                    </div>
                    
                    <div class="col-lg-6">

                          <v-select 
                          @option:selected=" 
                            formData.assessment_criteria_id = '';
                            "
                          name="level"
                          id="level"
                          :options="getLevel" 
                          :clearable="false"
                          label="name"
                          :class="{
                            'p-invalid': this.v$.formData.level.$error,
                          }"
                          v-model="formData.level"
                          :selectable="(option) => option.name !== formData.level?.name"

                          />

        
                          <v-errors
                            :vuelidateErrors="{
                              errors: this.v$.formData.level.$errors,
                              value: 'Level',
                            }"
                          ></v-errors>

                    </div>

                </div>

                <div class="row mb-3">
                    
                    <div class="col-lg-3">
                      <label for="type"> 
                          HRD Type 
                      </label>
                    </div>

                    <div class="col-lg-6">

                          <v-select 
                          :options="hrdTypes" 
                          label="name"
                          class="border-danger"
                          :selectable="(option) => option != formData.hrd_type"
                          v-model="formData.hrd_type"
                          :clearable="false"
                          />
                    </div>

                </div>

                <div class="row mb-3">
                    
                    <div class="col-lg-3">
                      <label for="type"> 
                          Assessment Criteria 
                          <span class="text-danger">*</span>
                      </label>
                    </div>

                    <div class="col-lg-6">

                          <v-select 
                          :options="filterAssessmentCriteria"
                          label="name"
                          class="border-danger"
                          :clearable="false"
                          :class="{
                            'p-invalid': this.v$.formData.assessment_criteria_id.$error,
                          }"
                          v-model="formData.assessment_criteria_id"
                          :loading="!assessmentCriteria.length"
                          :selectable="(val) => val.id != formData.assessment_criteria_id?.id"
                          
                          />
                         
                          <v-errors
                            :vuelidateErrors="{
                              errors: this.v$.formData.assessment_criteria_id.$errors,
                              value: 'Assessment Criteria',
                            }"
                          ></v-errors>

                    </div>

                </div>

                <div class="row mb-3">
                    
                    <div class="col-lg-3">
                      <label for="remark"> 
                          Lowest Score 
                          <span class="text-danger">*</span>
                      </label>
                    </div>

                    <div class="col-lg-6">

                          <input 
                          type="number"  
                          min="1"
                          max="5"
                          class="form-control" 
                          :class="{
                            'p-invalid': this.v$.formData.lowest_score.$error,
                          }"
                          v-model="formData.lowest_score"/>

          
                          <v-errors
                            :vuelidateErrors="{
                              errors: this.v$.formData.lowest_score.$errors,
                              value: 'Percentage',
                            }"
                          ></v-errors>

                    </div>

                </div>

                <div class="row mb-3">
                    
                    <div class="col-lg-3">
                      <label for="remark"> 
                          Highest Score 
                          <span class="text-danger">*</span>
                      </label>
                    </div>

                    <div class="col-lg-6">

                          <input 
                          type="number"  
                          min="1"
                          max="5"
                          class="form-control" 
                          :class="{
                            'p-invalid': this.v$.formData.highest_score.$error,
                          }"
                          v-model="formData.highest_score"/>

          
                          <v-errors
                            :vuelidateErrors="{
                              errors: this.v$.formData.highest_score.$errors,
                              value: 'Percentage',
                            }"
                          ></v-errors>

                    </div>

                </div>

                <div class="row mb-3">
                    
                    <div class="col-lg-3">
                      <label for="remark"> 
                          Status 
                          <span class="text-danger">*</span>
                      </label>
                    </div>

                    <div class="col-lg-6">

                          <v-select 
                          :options="['Active', 'Inactive']" 
                          :clearable="false"
                          :class="{
                            'p-invalid': this.v$.formData.status.$error,
                          }"
                          v-model="formData.status"
                          />

        
                          <v-errors
                            :vuelidateErrors="{
                              errors: this.v$.formData.status.$errors,
                              value: 'Status',
                            }"
                          ></v-errors>

                    </div>

                </div>
                
                <div class="row mb-3" v-show="formData.status == 'Inactive'">
                    
                    <div class="col-lg-3">
                      <label for="remark"> 
                          Remark 
                          <span class="text-danger">*</span>
                      </label>
                    </div>

                    <div class="col-lg-6">

                          <input 
                          name="percentage"
                          id="percentage"
                          type="text"  
                          class="form-control" 
                          :class="{
                            'p-invalid': this.v$.formData.remark.$error,
                          }"
                          v-model="formData.remark"/>

          
                          <v-errors
                            :vuelidateErrors="{
                              errors: this.v$.formData.remark.$errors,
                              value: 'Remark',
                            }"
                          ></v-errors>

                    </div>

                </div>
          
                <div 
                class="row" 
                >
                
                  <div class="col-md-6 mx-auto">

                        <div class="mt-3 mb-3 ms-3 text-center">

                          <router-link :to="{ name: 'ojt-criteria' }">

                            <button
                              type="button"
                              class="btn w-sm btn-secondary me-5"
                            >
                              Cancel
                            </button>

                          </router-link>

                          <button
                            :disabled="isLoading ? true : false"
                            type="button"
                            class="btn w-sm btn-success waves-effect waves-light px-3"
                            @click="updateOrientationCriteria()"
                          >
                            <span
                              v-if="isLoading"
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>

                            {{ isLoading == true ? "Loading..." : "Save" }}

                          </button>

                        </div>

                  </div>
              
                </div>

            </form>

          </div>
        </div>

      </div>
    </div>

  </div>
  
</template>

<script>

import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import { levels } from "../../../const";
import { types } from "../../../const";

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import validationErrors from "../../../mixins/validationErrors";



export default {

  setup() {

    const toast = useToast();
    return { v$: useVuelidate(), toast };
    
  },

  components: {
    vSelect,
  },

  mixins: [validationErrors],

  data() {

    return {
      hrdTypes: [{
        id: 1,
        name: 'Funtional Field Assessment',
        value: 'Functional'
      }, 
      {
        id: 2,
        name: 'Rule & Regulation Assessment',
        value: 'Rule'
      }
    ],
      formData : {
          name: "",
          program_ids: [],
          level : "",
          hrd_type: null,
          assessment_criteria_id: null,
          lowest_score: "",
          highest_score: "",
          type: "OJT",
          all_check : 1,
          status: "",
          remark : null
      },
      moduleList : [],
      assessmentCriteria : [],
      getLevel : levels,
      getType : types,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,

    };

  },

  validations () {

    return {
      formData : {
          name: {required},
          level: {required},
          program_ids: this.formData.all_check ? '' : {required},
          assessment_criteria_id: {required},
          lowest_score: {required},
          highest_score: {required},
          status: {required},
          remark : this.formData.status == 'Inactive' ? {required} : ''
      },
    }

  },

  methods: {
    async getModule() {

    await axios.get(`${this.baseUrl}admin/v1/programs`)
                .then((res) => this.moduleList = res.data.data)
    },

    async getAssessmentCriteria() {

        await axios.get(`${this.baseUrl}admin/v2/assessment-criteria?type=OJT&&status=1`)
                    .then((res) =>{
                      this.assessmentCriteria = res.data.data
                    })
    },

    async getOrientationCriteria() {

      await axios.get(`${this.baseUrl}admin/v2/hrd-criteria/${this.$route.params.id}`)
                  .then( (res) => {
                    let data = res.data.data
                    this.formData.name = data.name
                    this.formData.level = this.getLevel.filter((val) => val.name == data.level)[0]
                    this.formData.lowest_score = data.lowest_score
                    this.formData.highest_score = data.highest_score
                    this.formData.all_check = data.all_check
                    this.formData.program_ids = data.programs
                    this.formData.hrd_type = this.hrdTypes?.filter(type => data.hrd_type == type.value)[0];
                    this.formData.assessment_criteria_id = this.assessmentCriteria.filter((val)=> val.id == data.assessment_criteria_id)[0]
                    this.formData.status = data.status ? 'Active' : 'Inactive', 
                    this.formData.remark = data.remark != null ? data.remark : null
                  });

    },

    async updateOrientationCriteria() {
      
      const isFormCorrect = await this.v$.$validate()
     
      if (!isFormCorrect) 
          return
      
      this.isLoading = true
      this.formData.level = this.formData.level.name
      this.formData.hrd_type = this.formData.hrd_type?.value;
      this.formData.program_ids = this.formData.all_check ? 
                                  this.moduleList.map((val) => val.id) : 
                                  this.formData.program_ids.map((val) => val.id)
      this.formData.assessment_criteria_id = this.formData.assessment_criteria_id.id
      this.formData.status = this.formData.status == "Active" ? 1 : 0

      await axios.put(`${this.baseUrl}admin/v2/hrd-criteria/${this.$route.params.id}`, this.formData)

                  .then(() => {

                      this.isLoading = false;
                      this.$router.push({ name: "ojt-criteria" });
                      this.toast.success("Successfully Criteria Updated!");  
                     
                  })
      
    },
    selectedProgram(option) {

        return (!this.formData.program_ids.length )  ? true :
        this.formData.program_ids.find((p) => p.id == option.id) === undefined

    },
    

  },
  watch : {
        'formData.lowest_score'(data) {
            if(!(data > 0 && data <= 5)) {
              this.formData.lowest_score = ''
            }
        },
        'formData.highest_score'(data) {
            if(!(data > 0 && data <= 5)) {
              this.formData.highest_score = ''
            }
        }

    },
    computed : {
      filterAssessmentCriteria() {
        if(this.formData.all_check ) {
            return  this.assessmentCriteria.filter((val) => {
                  return val.all_check && val.level == this.formData.level?.name;
          })}else{
          return this.assessmentCriteria.filter((val)  => {
				if(this.formData.program_ids.length){
					let p_ids = val.programs.map((p) => p.id)
                  let flag = true;
                       Object.values(this.formData.program_ids.map((val)=>val.id)).some((p) => {
                          if(! p_ids.includes(p)){
                            flag = false;
                          }
        
                      })
                    return flag && val.level == this.formData.level?.name;
				}			
                  
          })
        }
    
      },  
  },
  async created() {
    await this.getModule()
    await this.getAssessmentCriteria()
    await this.getOrientationCriteria()
  },

};

</script>

